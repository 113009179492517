.MuiAvatar-root .MuiAvatar-img {
  margin-top: 50%;
  height: auto;
}

.active {
  color: var(--secondary);
  background: var(--primary);
  padding: 0.5rem;
}
