.container {
  height: calc(100vh - 68.5px);
  width: 100%;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
}

#greetingDiv {
  position: absolute;
  top: 25%;
  margin: 0px 40px 0px 40px;
}

#greeting {
  color: grey;
  margin-bottom: 10px;
}

.firstName {
  --c: 0, 0, 0;
}

.lastName {
  --c: 192, 165, 142;
}

.path {
  stroke-dasharray: 366;
  stroke-dashoffset: 366;
  animation: dash 5s linear forwards, col 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes col {
  0% {
    fill: none;
  }
  75% {
    fill: rgba(var(--c), 0.02);
  }
  100% {
    fill: rgba(var(--c, 1));
  }
}

#greetingBlurb {
  line-height: 25px;
}

@media only screen and (max-width: 600px) {
  .container {
    height: calc(100vh - 56px);
  }
  #greetingDiv {
    position: relative;
    z-index: 100;
  }

  #greeting {
    color: white;
  }
  #greetingBlurb {
    color: white;
  }

  .firstName {
    --c: 192, 165, 142;
  }

  .container::before {
    content: "";
    background-image: url("./headshot.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .container::after {
    content: "";
    background-color: rgb(0, 0, 0, 0.4);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 50;
  }
}
