#profilePicDiv {
  position: relative;
  height: calc(100vh - 68.5px);
}

#animateProfilePic {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 200px 200px 0px 0px;
  animation: opacity 6s linear forwards;
}

#profilePic {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 200px 200px 0px 0px;
}

@keyframes opacity {
  0% {
    opacity: 0%;
  }
  75% {
    opacity: 0%;
  }
  90% {
    opacity: 50%;
  }
  100% {
    opacity: 100%;
  }
}
