.projectCard:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.projectCard {
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
}
