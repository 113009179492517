#aboutGreeting {
  color: var(--secondary);
  background: var(--primary);
}

#services {
  background: var(--secondary);
  color: var(--primary);
}

#aboutPicDiv {
  align-items: center;
  height: calc(100vh - 68.5px);
}

#aboutPic {
  display: block;
  max-width: 90%;
  width: 400px;
  border-radius: 400px 400px 400px 400px;
  margin-left: auto;
  margin-right: auto;
}

#aboutTxt {
  text-align: left;
  line-height: 25px;
  margin: 1rem;
  margin-bottom: 3rem;
}

#contactIconsDiv {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.contactIcon:hover {
  transform: scale(1.5);
}
.contactIcon {
  padding-right: 5px;
  transition: transform 2s ease-in-out;
}

.servicesPaper:hover {
  transform: scale(1.05);
}
.servicesPaper {
  transition: transform 1s ease-in-out;
}

@media only screen and (max-width: 600px) {
  #aboutPicDiv {
    margin-bottom: 2rem;
    height: auto;
  }
}
